import React, { Component } from "react";
import Navbar from "../components/navbar";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";
import * as contactStyles from "../styles/css/contact.module.css"

class Contact extends Component {
    render(){
        return (
            <React.Fragment>
<Helmet>
            <title>Prenons contact - WDS</title>
            <meta name="title" content="Prenons contact - WDS" />
            <meta name="description" content="Un renseignement ? Une demande de devis ? Que vous soyez une entreprise, une association ou même un particulier, n'hésitez plus et contactez-nous !" />
        </Helmet>
          <Navbar/>
          <div className={contactStyles.container}>
            <div className={contactStyles.tmp}>
              <h1 className={contactStyles.titre}>Une question, un projet ? Contactez-nous !</h1>
              <p>N'hésitez pas à nous contacter par email, par téléphone ou en utilisant le formulaire ci-dessous.</p>
              <div className={contactStyles.contactContainer}>
                <form id="contact-form">
                  <div className={contactStyles.formContainer}>
                    <div className={contactStyles.inputLine}>
                      <div className={contactStyles.inputContainer}>
                        <input type="text" required id="name" />
                        <label className={contactStyles.inputLabel}>Nom*</label>  
                        
                      </div>
                      <div className={contactStyles.inputContainer}>
                        <input type="text" required id="prenom"/> 
                        <label className={contactStyles.inputLabel}>Prénom*</label>  
                        
                      </div>
                    </div>
                    <div className={contactStyles.inputLine}>
                      <div className={contactStyles.inputContainer}>
                        <input type="text" required id="email"/>
                        <label className={contactStyles.inputLabel}>Email*</label>
                        
                      </div>
                      <div className={contactStyles.inputContainer}>
                        <input type="text" required id="tel"/>  
                        <label className={contactStyles.inputLabel}>Téléphone*</label>
                                      
                      </div>
                    </div>
                    <div className={contactStyles.textareaContainer}>
                      <textarea required id="message"/>
                      <label className={contactStyles.inputLabel}>Message*</label> 
                      
                    </div>
                    <div className={contactStyles.checkContainer}>
                      <input required type="checkbox" className={contactStyles.check} id="check" noValidate onClick={this.handleClick}/>
                      <label className={contactStyles.labelCheck}>J'ai lu et j'accepte la <Link to="/" className={contactStyles.checkLink}>politique de confidentialité</Link></label>
                      
                    </div>
                   
                    <div className={contactStyles.checkContainer}>
                      <button className={contactStyles.btn}>Envoyer</button>
                    </div>
                  </div>
                </form>
                <div className={contactStyles.infosContainer}>
                  <div className={contactStyles.info}>
                    <h2>Nous téléphoner</h2>
                    <p>06.33.71.94.41</p>
                  </div>
                  <div className={contactStyles.info}>
                    <h2>Nous écrire</h2>
                    <p>contact@agencewds.fr</p>
                  </div>
                </div>
              </div>
              </div>
          </div>
            </React.Fragment>  
        )
    }
}

export default Contact;